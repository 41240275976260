import { GetParentResponse, ImpulseControlData } from 'src/types';

export function generateSearchParams(params: Record<string, string | number>) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) searchParams.append(key, value.toString());
  });
  return searchParams.toString();
}

export const getAllChildrenFromParentResponse = (
  response: GetParentResponse
) => {
  const children = response?.content
    ?.map((parent) =>
      parent.children.map((item) => ({
        ...item,
        parent: {
          username: parent.username,
          email: parent.email,
          firstName: parent.firstName,
          lastName: parent.lastName
        }
      }))
    )
    .flat();
  return children || [];
};

export function transformImpulseControlData(data: ImpulseControlData[]) {
  return data
    .sort(
      (a, b) => new Date(a.session).getTime() - new Date(b.session).getTime()
    )
    .map((d, i) => ({
      ...d,
      i: i + 1,
      'Focus Skills': Math.round(d.focus),
      'Impulse Control': Math.round(d.impulse)
    }));
}

export function formatTime(ms: number) {
  const seconds = Math.floor(ms / 1000);
  if (!seconds) return null;
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours} hours ${
      remainingMinutes ? remainingMinutes + ' minutes' : ''
    } `;
  } else {
    return `${remainingMinutes} minutes ${
      remainingSeconds ? remainingSeconds + ' seconds' : ''
    }`;
  }
}

export function renderValueBasedOnType(value: any) {
  const type = typeof value;
  switch (type) {
    case 'number':
      // if integer
      if (value % 1 === 0) {
        return value;
      }
      // if float
      return value.toFixed(2);

    case 'object':
      if (value instanceof Date) {
        return value.toLocaleDateString();
      }
      if (Array.isArray(value)) {
        return value.map((item) => (
          <>
            {renderValueBasedOnType(item)}
            <br />
          </>
        ));
      }
      if (value === null) return '-';
      return Object.keys(value)
        .map((key) => `${key}: ${value[key]}`)
        .join(', ');

    case 'boolean':
      return value ? 'Yes' : 'No';
    default:
      return value || '-';
  }
}

export function camelCaseToTitleCase(inputString: string) {
  return inputString
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add a space before each uppercase letter
    .replace(/^./, function (str) {
      // Capitalize the first letter
      return str.toUpperCase();
    });
}

export function transformObjToSeries(obj: Record<string, any>) {
  const keys = Object.keys(obj || {});
  return keys.map((key) => ({
    x: key.split(' ')[0]?.replace('2023-', ''),
    y: obj[key]
  }));
}

export function convertTimestampDataToMinutesSeries(data: any[]) {
  const firstTimestamp = Math.min(...data.map((d) => d.timestamp));
  return data.map((d) => {
    const minutes = (d.timestamp - firstTimestamp) / 1000 / 60;

    return {
      ...d,
      value: parseFloat(d.value?.toFixed ? d.value?.toFixed(2) : d.value),
      minutes: Number(
        minutes === Math.floor(minutes)
          ? Math.floor(minutes)
          : minutes?.toFixed
          ? minutes?.toFixed(1)
          : minutes
      )
    };
  });
}

import { useInfiniteQuery } from 'react-query';
import { generateSearchParams } from '..';

export default function usePagination(
  apiFunction,
  name: string,
  pageSize = 20,
  filters?: any
) {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuery(
    `${name}Pagination${filters ? generateSearchParams(filters) : ''}`,
    apiFunction,
    {
      meta: {
        size: pageSize,
        filters
      },
      getNextPageParam: (
        lastPage: { total: number; content: any[] },
        allPages
      ) => {
        const currentPage = allPages.length || 0;
        if (currentPage < lastPage.total || (lastPage as any).totalElements) {
          return {
            page: currentPage,
            size: pageSize
          };
        }
        return {
          page: null,
          size: pageSize
        };
      }
      // pass the page size to the query function
    }
  );

  return {
    data: data ? data.pages.flatMap((page) => page.content) : [],
    total: data
      ? data.pages[0].total || (data.pages[0] as any).totalElements
      : 0,
    isLoading,
    isFetchingNextPage,
    fetchNextPage: () => {
      const total = data.pages[0].total || (data.pages[0] as any).totalElements;
      if (total <= pageSize) {
        return;
      }
      const totalInData = data.pages.flatMap((page) => page.content).length;
      if (totalInData >= total) {
        return;
      }
      fetchNextPage();
    },
    hasNextPage,
    refetch
  };
}

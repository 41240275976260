import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Missions from './content/pages/Missions';
import CognitiveSkills from './content/pages/CognitiveSkills';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));
const Player = Loader(lazy(() => import('src/content/pages/Player')));
const EditPlayer = Loader(lazy(() => import('src/content/pages/EditPlayer')));
// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const Users = Loader(lazy(() => import('src/content/pages/Users')));
const Coaches = Loader(lazy(() => import('src/content/pages/Coaches')));
const ImportJobs = Loader(lazy(() => import('src/content/pages/ImportJobs')));
const EditUser = Loader(lazy(() => import('src/content/pages/EditUser')));
const Attempts = Loader(lazy(() => import('src/content/pages/Attempts')));
const Devices = Loader(lazy(() => import('src/content/pages/Devices')));
const Headset = Loader(lazy(() => import('src/content/pages/Headset')));
const Players = Loader(lazy(() => import('src/content/pages/Players')));
const AddPlayer = Loader(lazy(() => import('src/content/pages/AddPlayer')));
const EditCoach = Loader(lazy(() => import('src/content/pages/EditCoach')));
const AddCoach = Loader(lazy(() => import('src/content/pages/AddCoach')));
const AddImport = Loader(lazy(() => import('src/content/pages/AddImport')));
const AssignHeadset = Loader(
  lazy(() => import('src/content/pages/AssignHeadset'))
);
const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: 'overview',
        element: <Navigate to="/dashboard" replace />
      },

      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      }
      // {
      //   path: '*',
      //   element: <Status404 />
      // }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'players/:username',
        element: <Player />
      },
      {
        path: 'users/:username',
        element: <EditUser />
      },
      {
        path: 'players/:username/edit',
        element: <EditPlayer />
      },
      {
        path: 'players/add',
        element: <AddPlayer />
      },
      {
        path: 'attempts',
        element: <Attempts />
      },
      {
        path: 'devices',
        element: <Devices />
      },
      {
        path: 'devices/:headsetId/assign',
        element: <AssignHeadset />
      },
      {
        path: 'devices/add',
        element: <Headset />
      },
      {
        path: 'players',
        element: <Players />
      },
      {
        path: 'dashboard',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      },
      {
        path: 'users',
        element: <Users />
      },
      {
        path: 'missions',
        element: <Missions />
      },
      {
        path: 'cognitive-skills',
        element: <CognitiveSkills />
      },
      {
        path: 'coaches',
        element: <Coaches />
      },
      {
        path: 'coaches/add',
        element: <AddCoach />
      },
      {
        path: 'coaches/:username/edit',
        element: <EditCoach />
      },
      {
        path: 'imports',
        element: <ImportJobs />
      },
      {
        path: 'imports/:username/add',
        element: <AddImport />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
